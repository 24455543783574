<template>
  <v-container
    fill-height
    fluid
    class="ml-3"
    ref="buttonContainer"
    v-resize="onResize"
  >
    <v-row align="center" justify="center" style="" no-gutters>
      <v-col
        v-if="enableBack"
        sm="2"
        md="1"
        class="mr-1"
        style="max-width: 140px"
      >
        <!-- <router-link to="?f=testing&f2=testStatus">
          <primaryButton
            label="Back"
            :icon="'mdi-step-backward'"
            type="button"
            color="primary"
            elevation="1"
            id="backButton"
          ></primaryButton>
        </router-link> -->
        <backButton> </backButton>
      </v-col>
      <v-col
        v-if="enableTotal"
        sm="2"
        md="1"
        class="mr-1"
        style="max-width: 100px"
      >
        <v-btn
          :id="'weightedButton_total'"
          :width="100"
          height="38"
          color="black"
          :class="['white--text']"
          :readonly="true"
          depressed
        >
          <div v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">
            <span :id="'statusCount_total'">{{ totalItems }}</span>
          </div>
          <div v-else>
            <span v-if="totalItems < 10000">Total </span>
            <span :id="'statusCount_total'">{{ totalItems }}</span>
          </div>
        </v-btn>
      </v-col>
      <v-col>
        <div :style="cssProps">
          <v-btn-toggle
            v-model="localSelected"
            dense
            background-color="white"
            multiple
            class="loggingButtons white--text"
            data-type="loggingButtons"
            :style="'min-width:' + window.width + 'px'"
          >
            <v-btn
              v-for="(info, lineIndex) in items"
              :title="buttonTitle(info)"
              :id="'weightedButton_' + info.name"
              :min-width="calculatedWidth(info)"
              :max-width="calculatedWidth(info)"
              :width="calculatedWidth(info)"
              height="40"
              :key="lineIndex"
              :outlined="!selected.includes(info.index) && selected.length != 0"
              :color="info.color"
              :value="info.index"
              :class="[
                selected.length == 0 ? 'white--text' : '',
                statusBarBig ? 'h40' : 'h25',
              ]"
              :style="
                !selected.includes(info.index) && selected.length != 0
                  ? 'background:linear-gradient(to top,rgba(255, 255, 255,0.5),rgba(255, 255, 255,0.5)) var(--v-' +
                    info.color +
                    ')  !important;'
                  : '2px solid #ccc !important'
              "
              style="border-color: white !important"
            >
              <div v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">
                <span :id="'statusCount_' + info.name">{{
                  bePositive(info.count)
                }}</span>
              </div>
              <div v-else>
                {{ info.name }}
                <span :id="'statusCount_' + info.name">
                  {{ bePositive(info.count) }}</span
                >
              </div>
            </v-btn>
          </v-btn-toggle>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import primaryButton from "@/commonComponents/primaryButton.vue";
import backButton from "@/components/dynamic/backButton.vue";
import resize from "vue-resize-directive";

export default {
  components: { /*primaryButton, */ backButton },
  directives: {
    resize,
  },
  props: {
    items: {},
    totalItems: {},
    selected: {},
    enableBack: { type: Boolean, default: true },
    enableTotal: { type: Boolean, default: false },
    statusBarBig: { type: Boolean, default: true },
  },
  data() {
    return {
      //  selected: [],
      minVal: 180,
      window: {
        width: 0,
        height: 0,
      },
    };
  },

  created() {
    window.addEventListener("resize", this.handleResize);
    this.$nextTick(() => {
      this.handleResize();
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  computed: {
    cssProps() {
      var themeColors = {};
      Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
        themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color];
      });
      return themeColors;
    },
    localSelected: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit("selectedItem", val);
      },
    },
    sum() {
      let sum = 0;
      Object.values(this.items).forEach((info) => {
        if(typeof info.count!=="undefined"){
          let width = (info.count / this.totalItems) * this.window.width;
          if (width < this.minVal) {
            width = this.minVal;
          }
          sum += width;
        }
      });
      return sum;
    },
  },
  methods: {
    buttonTitle(info){
      let title = info.name
      if(info.name=="PE"){
         title ="Processing Error"
      }else if(info.name=="PW"){
         title ="Processing Warning"
      }else if(info.name=="RE"){
         title ="Result Error"
      }else if(info.name=="RW"){
         title ="Result Warning"
      }
      return title;
    },
    onResize() {
      this.$nextTick(() => {
        this.handleResize();
      });
    },
    bePositive(value) {
      return value < 0 || typeof value==="undefined" ? 0 : value;
    },
    calculatedWidth(info) {
      let width;
      if (this.totalItems == 0) {
        width = this.window.width / Object.keys(this.items).length;
      } else {
        //width = (info.count * 100) / this.totalItems + "%";
        if(typeof info.count!=="undefined"){
          width = (info.count / this.totalItems) * this.window.width;
        }
        if (width < this.minVal) {
          width = this.minVal;
        }
        width = (width * this.window.width) / this.sum;
      }
      width += "px";

      return width;
    },

    handleResize() {
      // let subtract = 65;
      // linear equation of two points
      //let subtract = 0.009 * window.innerWidth + 60;
      let subtract = 77;
      // if (window.innerWidth > 1920) {
      //   subtract = 80;
      // }
      //  console.log(subtract,window.innerWidth)

      if (this.enableBack) {
        subtract += 140;
      }
      if (this.enableTotal) {
        subtract += 110;
      }
      // if (window.innerWidth>500){
      //   subtract=210
      // }

      let totalWidth;
      if (typeof this.$refs.buttonContainer != "undefined") {
        totalWidth = this.$refs.buttonContainer.clientWidth;
        subtract -= 42;
      } else {
        totalWidth = window.innerWidth;
      }

      this.window.width = totalWidth - subtract;
      this.window.height = window.innerHeight - 136;
    },
  },
};
</script>
<style>
.v-application--is-ltr .v-btn-toggle > .v-btn.v-btn:not(:first-child) {
  border-left: 2px solid white !important;
}
.h40 {
  height: 40px !important;
}
.h25 {
  height: 25px !important;
}
</style>