<template>
  <div>
    <primaryButton
      label="Back"
      :icon="'mdi-step-backward'"
      type="button"
      color="primary"
      elevation="1"
      @click="goBack"
      id="backButton"
    ></primaryButton>
    <span class="pr-2"></span>
  </div>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";
export default {
  components: {
    primaryButton,
  },
  methods: {
    goBack() {
      localStorage.setItem('back', 'true');
      return this.$router.go(-1);
    },
  },
};
</script>